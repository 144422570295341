$color-primary: #023B91;
$color-secondary: #ff0;
$color-background: #fff;
$color-text: #1f1f1f;
$color-shadow: #1f1f1f;
$color-light: #eee;

$width-body: 800px;
$time-animation: 600ms;

@mixin timeline($start, $end, $width) {
    @for $i from 1 through length($width) {
        $w: nth($width, $i);
        dt:nth-of-type(#{length($width) - $i + 1})::after {
            left: #{(nth($w, 1) - $start) / ($end - $start) * 100 + '%'};
            @if length($w) > 1 {
                width: #{(nth($w, 2) - nth($w, 1)) / ($end - $start) * 100 + '%'};
            } @else {
                border: 8px solid $color-secondary;
                border-radius: 50%;
                bottom: -5px;
                width: 0;
            }
        }
    }
}

:root {
    --color-background: $color-background;
    --color-primary: $color-primary;
}

::selection {
    background: $color-secondary;
    color: $color-text;
}

.🖼 {
    display: none;
    height: 0;
    overflow: hidden;
    visibility: hidden;
    width: 0;
}

.🃏 {
    display: inline-block;
    fill: currentColor;
    height: 1em;
    width: 1em;
}

.👾 {
    color: $color-text;
    font: 300 1.1em 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1.65;
    margin: 0;
    padding: 0;
    text-rendering: optimizelegibility;
}

.🔝 {
    background: url('../img/cover-blue.jpg') 80% 40%;
    background-color: var(--color-primary);
    background-size: cover;
    color: $color-background;
    padding: 3.5em 1em;
    text-align: center;

    &__😀 {
        border-radius: 50%;
        box-shadow: 0 0 6px $color-shadow;
        display: block;
        height: 200px;
        margin: 1em auto;
    }

    &__🎬 {
        filter: drop-shadow(0 0 3px $color-shadow);
        font-size: 2.3em;
        font-weight: bold;
        margin: 0.5em auto 0;

        em {
            font-weight: normal;
        }
    }
}

.🌍 {

    &__⚓️ {
        color: currentColor;
        display: inline-block;
        filter: drop-shadow(0 0 3px $color-shadow);
        font-size: 2.275em;
        height: 1.4em;
        line-height: 1.4;
        transition: all ease 100ms;
        width: 1.4em;
        will-change: transform;
        transform: scale(0.77);

        &:hover {
            transform: scale(1);
        }

        @media (pointer: coarse) {
            font-size: 2em;
        }
    }
}

.🖥 {
    margin: 0 auto;
    max-width: $width-body;
    padding: 2em 20px 100px;

    &__⚓️ {
        border-bottom: 1px dotted;
        color: $color-text;
        position: relative;
        text-decoration: none;
        transition: all ease-in-out 200ms;

        &:hover {
            color: var(--color-primary);
        }
    }
}

dt {
    margin: 1.5em 0 0.5em;
    position: relative;
    padding-bottom: 15px;
    page-break-after: avoid;
}

dd {
    margin-bottom: 1em;
    font-size: 0.95em;
    page-break-before: avoid;
    page-break-inside: avoid;
}

.🏢,
.🎓 {

    dt::before,
    dt::after {
        border-bottom: 5px solid $color-secondary;
        bottom: 0;
        content:  '';
        position: absolute;
    }

    dt::before {
        border-bottom-color: $color-light;
        left: 0;
        width: 100%;
    }
}

.🏢 {
    @include timeline(0, 81, (0 37, 37 44, 44 60, 60 77, 77 81));
    // 81 = gennaio 2018
}

.🎓 {
    @include timeline(1998, 2016, (1998 2003, 2007.4, 2008.5, 2003 2010, 2010 2016));
}

.🎬 {
    align-items: center;
    color: $color-primary;
    color: var(--color-primary);
    display: flex;
    margin: 1.5em 0 0.5em;
    padding: 0.5em 0;
    position: relative;

    &__⚓️ {
        color: currentColor;
        display: flex;
        margin-right: 0.3em;
    }
}

table {

    tr {
        vertical-align: top;

        th {
            text-align: right;
            white-space: nowrap;
        }

        th,
        td {
            padding: 5px;
        }
    }
}

.🔴 {
    background-color: $color-background;
    background-color: var(--color-background);
    border: 2px solid currentColor;
    border-radius: 5px;
    box-sizing: border-box;
    color: $color-primary;
    color: var(--color-primary);
    cursor: pointer;
    display: inline-block;
    margin: 1.5em 0;
    padding: 0.5em 1.5em;
    text-decoration: none;
    transition: all ease-in-out 100ms;

    &:hover {
        box-shadow: 0 0 10px currentColor;
    }

    &--🃏 {
        align-items: center;
        border-radius: 50%;
        bottom: 20px;
        display: flex;
        font-size: 30px;
        height: 60px;
        justify-content: center;
        margin: 0;
        padding: 0;
        position: fixed;
        right: 20px;
        transition: all ease-in 1000ms;
        width: 60px;
        will-change: transform;

        &.⬅️ {
            right: calc(100vw - 80px);
            transform: rotate(-720deg);
        }
    }

    input {
        visibility: hidden;
        width: 0;
        height: 0;
        opacity: 0;
        position: absolute;
    }
}

.🔛 {
    text-align: center;
}

.📋 {
    bottom: 20px;
    display: flex;
    position: fixed;
    right: 20px;
    will-change: transform;
}

.✅\@💲 {
    display: none;
    display: var(--cp, flex);
}

.🚫\@💲 {
    display: var(--cp, none);
}

@media print {

    .👾 {
        font-size: 1em;
        line-height: 1.5;
        max-width: 700px;
        margin: auto;
    }

    .🚫\@🖨 {
        display: none;
    }

    .🖥__⚓️[href]::after {
        content: ' [' attr(href) ']';
        opacity: 0.5;
    }

    .🎬 {
        margin: 1em 0;
        padding: 0.3em 0;
        page-break-inside: avoid;
    }

    .🖥 {
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
    }

    .🔝 {
        background: none;
        box-sizing: content-box;
        color: $color-text;
        display: block;
        margin: auto;
        max-width: $width-body;
        min-height: inherit;
        padding-bottom: 0;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 0;
        text-align: left;
        text-shadow: none;

        &__🎬 {
            color: $color-primary;
            color: var(--color-primary);
            filter: none;
            margin-bottom: 0.5em;
        }

        &__😀 {
            float: right;
            width: 180px;
            height: 180px;
        }
    }

    .🌍__⚓️ {
        color: $color-text;
        display: block;
        filter: none;
        font-size: 0.8em;
        height: 1.5em;
        margin-bottom: 0;
        text-decoration: none;
        transform: none;
        width: auto;

        &[href]::after {
            content: ' ' attr(href);
            opacity: 0.75;
        }

        .🃏 {
            font-size: 1.3em;
            margin-right: 0.3em;
        }
    }
}
